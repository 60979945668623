import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next'; 
import i18n from './i18n'; 
import Home from './view/user/Home';
import WatchDetails from './view/user/WatchDetails';
import Auth from './view/user/components/Auth';
import Dashboard from './view/admin/Dashboard';
import AddWatch from './view/admin/components/AddWatch';
import WatchesPage from './view/user/WatchesPage';
import Contact from './view/user/Contact';
import About from './view/user/About'; 
import './App.css';

function App() {
  return (
    <I18nextProvider i18n={i18n}> 
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/auth" element={<Auth />} />
          <Route path="/add-watch" element={<AddWatch />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/watches" element={<WatchesPage />} />
          <Route path="/watch/:id" element={<WatchDetails />} />
        </Routes>
      </Router>
    </I18nextProvider>
  );
}

export default App;
