// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";           // Import Firebase Authentication
import { getDatabase } from "firebase/database";   // Import Firebase Realtime Database
import { getStorage } from "firebase/storage";     // Import Firebase Storage

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCpg4dpDunEcnFW7T8hla7qEIrq4_xsHTQ",
  authDomain: "timeless-luxury-watches.firebaseapp.com",
  databaseURL: "https://timeless-luxury-watches-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "timeless-luxury-watches",
  storageBucket: "timeless-luxury-watches.appspot.com",
  messagingSenderId: "409868913171",
  appId: "1:409868913171:web:d3ee90691b506c6f3e8de6",
  measurementId: "G-2NM5K9ZDHL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firebase services
export const auth = getAuth(app);               // Initialize Firebase Authentication
export const db = getDatabase(app);             // Initialize Firebase Realtime Database
export const storage = getStorage(app);         // Initialize Firebase Storage
