import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserController from '../../../controller/UserController';

const Navbar = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await UserController.logout();
      localStorage.removeItem('sessionTime'); 
      navigate('/auth'); 
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const styles = {
    navbar: {
      width: '220px',
      minHeight: '100vh',
      backgroundColor: '#2C3E50',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '20px',
      paddingLeft: '20px',
      paddingRight: '20px',
      position: 'fixed',
      left: 0,
      top: 0,
      boxShadow: '2px 0 5px rgba(0,0,0,0.1)',
    },
    brand: {
      fontSize: '1.8rem',
      marginBottom: '2rem',
      color: '#ecf0f1',
      textDecoration: 'none',
      fontWeight: 'bold',
      letterSpacing: '1px',
    },
    navList: {
      listStyleType: 'none',
      paddingLeft: 0,
    },
    navItem: {
      marginBottom: '1.5rem',
    },
    navLink: {
      color: '#ecf0f1',
      textDecoration: 'none',
      fontSize: '1.1rem',
      padding: '10px 15px',
      borderRadius: '5px',
      backgroundColor: '#34495e',
      display: 'block',
      transition: 'background-color 0.3s ease, color 0.3s ease',
    },
    navLinkHover: {
      backgroundColor: '#1abc9c',
      color: '#fff',
    },
    btnDanger: {
      backgroundColor: '#e74c3c',
      borderColor: '#c0392b',
      fontSize: '1.1rem',
      color: '#fff',
      padding: '10px 15px',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      textAlign: 'center',
      textDecoration: 'none',
      transition: 'background-color 0.3s ease',
      width: '100%',
    },
    btnDangerHover: {
      backgroundColor: '#c0392b',
    },
  };

  const [hoveredLink, setHoveredLink] = useState(null);

  return (
    <nav style={styles.navbar}>
      <a style={styles.brand} href="/dashboard">Admin Dashboard</a>
      <ul style={styles.navList}>
        <li style={styles.navItem}>
          <a
            style={hoveredLink === 'watches' ? { ...styles.navLink, ...styles.navLinkHover } : styles.navLink}
            onMouseEnter={() => setHoveredLink('watches')}
            onMouseLeave={() => setHoveredLink(null)}
            onClick={() => navigate('/dashboard?tab=manageWatches')}
          >
            Manage Watches
          </a>
        </li>
        <li style={styles.navItem}>
          <a
            style={hoveredLink === 'users' ? { ...styles.navLink, ...styles.navLinkHover } : styles.navLink}
            onMouseEnter={() => setHoveredLink('users')}
            onMouseLeave={() => setHoveredLink(null)}
            onClick={() => navigate('/dashboard?tab=manageUsers')}
          >
            Manage Users
          </a>
        </li>
        {/* New Manage Contacts link */}
        <li style={styles.navItem}>
          <a
            style={hoveredLink === 'contacts' ? { ...styles.navLink, ...styles.navLinkHover } : styles.navLink}
            onMouseEnter={() => setHoveredLink('contacts')}
            onMouseLeave={() => setHoveredLink(null)}
            onClick={() => navigate('/dashboard?tab=manageContacts')}
          >
            Manage Contacts
          </a>
        </li>
        <li style={styles.navItem}>
          <button
            style={hoveredLink === 'logout' ? { ...styles.btnDanger, ...styles.btnDangerHover } : styles.btnDanger}
            onMouseEnter={() => setHoveredLink('logout')}
            onMouseLeave={() => setHoveredLink(null)}
            onClick={handleLogout}
          >
            Logout
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
