import React, { useEffect, useState } from 'react';
import WatchController from '../../controller/WatchController';
import WatchItem from './WatchItem';

const WatchList = () => {
  const [watches, setWatches] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await WatchController.fetchWatches();
      setWatches(data.slice(0, 20));
    };

    fetchData();
  }, []);

  return (
    <div className="container" style={styles.watchList}>
      {watches.map((watch, index) => (
        <React.Fragment key={watch.id}>
          <WatchItem watch={watch} />
          {(index + 1) % 3 === 0 && window.innerWidth > 768 && <hr style={styles.horizontalLine} />}
          {(index + 1) % 2 === 0 && window.innerWidth <= 768 && <hr style={styles.horizontalLine} />}
        </React.Fragment>
      ))}
    </div>
  );
};
const styles = {
  watchList: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  horizontalLine: {
    width: '100%',
    borderTop: '1px solid #10381f',
    margin: '20px 0',
  },
};


const watchItemStyles = {
  cardContainer: {
    flex: '1 1 30%',
    margin: '15px',
    maxWidth: '30%',
  },
  '@media (max-width: 768px)': {
    cardContainer: {
      flex: '1 1 45%',
      maxWidth: '45%',
    },
  },
};


export default WatchList;
