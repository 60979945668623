import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserController from '../../../controller/UserController';
import { auth } from '../../../config/firebaseConfig';
import timelessLogo from '../../img/logogreen.png'

const Auth = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleAuth = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      if (isRegistering) {
        const newUser = await UserController.register(email, password, name);
        alert('Registration successful!');
        navigate('/');
      } else {
        const user = await UserController.login(email, password);


        const userData = await UserController.getUserById(user.uid);

        if (userData.isAdmin) {
          navigate('/dashboard');
        } else {
          navigate('/');
        }
      }
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div style={styles.authContainer}>
      <div style={styles.formWrapper}>
        <img src={timelessLogo} alt="Timeless Logo" style={styles.logo} />
        <h2 style={styles.title}>
          {isRegistering ? 'Register' : 'Login'} as Admin
        </h2>{error && <p style={styles.error}>{error}</p>}
        <form onSubmit={handleAuth}>
          {isRegistering && (
            <div className="mb-3">
              <label htmlFor="name" className="form-label">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                style={styles.input}
              />
            </div>
          )}
          <div className="mb-3">
            <label htmlFor="email" className="form-label">Email address</label>
            <input
              type="email"
              className="form-control"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              style={styles.input}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">Password</label>
            <input
              type="password"
              className="form-control"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              style={styles.input}
            />
          </div>
          <button type="submit" style={styles.button}>
            {isRegistering ? 'Register' : 'Login'}
          </button>
        </form>
        <button
          style={styles.toggleButton}
          onClick={() => setIsRegistering(!isRegistering)}
        >
          {isRegistering ? 'Already have an account? Login' : 'Need an account? Register'}
        </button>
        <button
          style={styles.homeButton}
          onClick={() => navigate('/')}
        >
          Back to Home
        </button>
      </div>
    </div>
  );
};

const styles = {
  authContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f0f2f5',
  },
  formWrapper: {
    backgroundColor: '#ffffff',
    padding: '40px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    maxWidth: '400px',
    width: '100%',
    textAlign: 'center',
  },
  title: {
    marginBottom: '20px',
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#10381f',
    fontFamily: 'Red Rose, sans-serif',
  },
  error: {
    color: 'red',
    marginBottom: '15px',
  },
  input: {
    width: '100%',
    padding: '10px',
    marginBottom: '15px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  button: {
    width: '100%',
    padding: '10px',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
    backgroundColor: '#10381f'
  },
  logo: {
    height: '50px',
    marginBottom: '20px'
  },
  toggleButton: {
    marginTop: '20px',
    backgroundColor: 'transparent',
    color: '#10381f',
    border: 'none',
    cursor: 'pointer',
    fontSize: '14px',
    textDecoration: 'underline',
  },
  homeButton: {
    marginTop: '10px',
    backgroundColor: '#58BC82',
    color: '#fff',
    border: 'none',
    padding: '10px',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '14px',
    width: '100%',
  },
};

export default Auth;
