import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import UserController from '../../controller/UserController';
import { auth } from '../../config/firebaseConfig';
import Navbar from './components/Navbar';
import ManageWatches from './ManageWatches';
import ManageUsers from './ManageUsers';
import ManageContacts from './ManageContacts'; 

const DashboardSpacer = () => <div style={{backgroundColor:'#f0f2f5', height: '100px' }}></div>; 

const Dashboard = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('');
  const [isNavbarVisible, setIsNavbarVisible] = useState(true); 
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkAdmin = async () => {
      const sessionTime = localStorage.getItem('sessionTime');
      const currentTime = new Date().getTime();

      if (sessionTime && currentTime - sessionTime < 5 * 60 * 1000) {
        setIsAdmin(true);
        setLoading(false);
        return;
      }

      const user = auth.currentUser;
      if (user) {
        try {
          const userData = await UserController.getUserById(user.uid);
          if (userData.isAdmin) {
            setIsAdmin(true);
            localStorage.setItem('sessionTime', currentTime);
          } else {
            alert('You are not authorized to view this page.');
            navigate('/');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          navigate('/');
        }
      } else {
        navigate('/auth');
      }

      setLoading(false);
    };

    checkAdmin();
  }, [navigate]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setActiveTab(params.get('tab') || 'manageWatches');
  }, [location]);

  const toggleNavbar = () => {
    setIsNavbarVisible(!isNavbarVisible);
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!isAdmin) {
    return null;
  }

  const styles = {
    dashboardContainer: {
      display: 'flex',
      minHeight: '100vh',
    },
    dashboardContent: {
      marginLeft: isNavbarVisible ? '220px' : '0', 
      padding: '20px',
      flexGrow: 1,
      backgroundColor: '#f0f2f5',
      transition: 'margin-left 0.3s ease',
    },
    toggleButton: {
      position: 'fixed',
      top: '10px',
      left: isNavbarVisible ? '230px' : '10px', 
      backgroundColor: '#007bff',
      color: '#fff',
      padding: '10px',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      zIndex: 1001,
    },
    welcomeText: {
      marginBottom: '30px', 
    },
  };

  return (
    <>
    <DashboardSpacer />
    <div style={styles.dashboardContainer}>
      <button style={styles.toggleButton} onClick={toggleNavbar}>
        {isNavbarVisible ? 'Hide' : 'Show'} Navbar
      </button>
      {isNavbarVisible && <Navbar />}
      <div style={styles.dashboardContent}>
        <h1 style={styles.welcomeText}>Admin Dashboard</h1>
        <p style={styles.welcomeText}>Welcome, Admin! Here you can manage the application.</p>

        {activeTab === 'manageWatches' && <ManageWatches />}
        {activeTab === 'manageUsers' && <ManageUsers />}
        {activeTab === 'manageContacts' && <ManageContacts />} 
      </div>
    </div>
    </>
  );
};

export default Dashboard;
