import React, { useState } from 'react';
import WatchController from '../../../controller/WatchController';
import { Button, TextField, Select, MenuItem, InputLabel, FormControl, Typography, Box, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Stack, Radio } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import DeleteIcon from '@mui/icons-material/Delete';

const AddWatch = ({ onAddWatch, onClose }) => {
  const [name, setName] = useState('');
  const [brand, setBrand] = useState('');
  const [refNumber, setRefNumber] = useState('');
  const [price, setPrice] = useState('');
  const [year, setYear] = useState('');
  const [state, setState] = useState('NEW');
  const [caseSize, setCaseSize] = useState('');
  const [inStock, setInStock] = useState(true);
  const [images, setImages] = useState([]);
  const [mainImageIndex, setMainImageIndex] = useState(null); 
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleAddWatch = async (e) => {
    e.preventDefault();
    setError(null);

    if (!name || !brand || !refNumber || !price || !year || !caseSize || images.length === 0) {
      setError('Please fill out all fields and add at least one image.');
      return;
    }

    const priceValue = price;
    const yearValue = parseInt(year);

    if (isNaN(yearValue)) {
      setError('Please ensure that the year is a valid number.');
      return;
    }

    setLoading(true);

    try {
      console.log('Starting watch addition process...');
      const imageFiles = images.map((image) => image.file);

      if (mainImageIndex !== null && mainImageIndex >= 0) {
        const [mainImage] = imageFiles.splice(mainImageIndex, 1); 
        imageFiles.unshift(mainImage); 
      }

      const newWatch = await WatchController.addWatch(
        name,
        brand,
        refNumber,
        priceValue,
        imageFiles,
        yearValue,
        state,
        caseSize,
        inStock
      );

      console.log('New watch added:', newWatch);
      onAddWatch(newWatch);
      resetForm();
      onClose();
    } catch (err) {
      console.error('Error adding watch:', err);
      setError('Failed to add watch: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const onDrop = (acceptedFiles) => {
    const newImages = acceptedFiles.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));
    setImages((prevImages) => [...prevImages, ...newImages]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: true,
  });

  const removeImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    if (mainImageIndex === index) {
      setMainImageIndex(null); 
    }
  };

  const resetForm = () => {
    setName('');
    setBrand('');
    setRefNumber('');
    setPrice('');
    setYear('');
    setCaseSize('');
    setImages([]);
    setState('NEW');
    setInStock(true);
    setMainImageIndex(null); 
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Add New Watch</DialogTitle>
      <DialogContent dividers>
        {error && (
          <Typography variant="body2" color="error" align="center">
            {error}
          </Typography>
        )}
        <form onSubmit={handleAddWatch}>
          <FormControl fullWidth margin="normal">
            <TextField label="Name" value={name} onChange={(e) => setName(e.target.value)} required />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField label="Brand" value={brand} onChange={(e) => setBrand(e.target.value)} required />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField label="Reference Number" value={refNumber} onChange={(e) => setRefNumber(e.target.value)} required />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField label="Price" value={price} onChange={(e) => setPrice(e.target.value)} required />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField label="Year" type="number" value={year} onChange={(e) => setYear(e.target.value)} required />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>State</InputLabel>
            <Select value={state} onChange={(e) => setState(e.target.value)} required>
              <MenuItem value="NEW">New</MenuItem>
              <MenuItem value="USED">Used</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField label="Case Size" value={caseSize} onChange={(e) => setCaseSize(e.target.value)} required />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>In Stock</InputLabel>
            <Select value={inStock} onChange={(e) => setInStock(e.target.value === 'true')} required>
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <Box {...getRootProps()} sx={{ border: '2px dashed #ccc', padding: 2, textAlign: 'center' }}>
              <input {...getInputProps()} />
              <Typography variant="body2">
                Drag 'n' drop some images here, or click to select files
              </Typography>
            </Box>

            <Stack direction="row" spacing={2} sx={{ marginTop: 2, flexWrap: 'wrap' }}>
              {images.map((image, index) => (
                <Box key={index} sx={{ width: '100px', position: 'relative' }}>
                  <img
                    src={image.preview}
                    alt={`preview-${index}`}
                    style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                  />
                  <IconButton
                    onClick={() => removeImage(index)}
                    sx={{ position: 'absolute', top: 5, right: 5, backgroundColor: 'rgba(0,0,0,0.5)', color: '#fff' }}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <Radio
                    checked={mainImageIndex === index}
                    onChange={() => setMainImageIndex(index)}
                    value={index}
                    name="mainImage"
                    sx={{ position: 'absolute', bottom: 5, left: 5, color: '#fff' }}
                  />
                </Box>
              ))}
            </Stack>
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleAddWatch}
          color="primary"
          variant="contained"
          disabled={loading} 
        >
          {loading ? 'Adding Watch...' : 'Add Watch'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddWatch;
