import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../img/logogreen.png';
import placeholderImage from '../img/logozez.png';

const WatchItem = ({ watch }) => {
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [imageLoaded, setImageLoaded] = useState(false);


  useEffect(() => {
    const preloadImage = new Image();
    preloadImage.src = watch.imageUrls[0];
    preloadImage.onload = () => setImageLoaded(true); 
  }, [watch.imageUrls]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isMobile = windowWidth <= 768;

  const styles = {
    cardContainer: {
      flex: isMobile ? '0 0 100%' : '0 0 30%',
      margin: isMobile ? '10px 0' : '15px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
    },
    card: {
      backgroundColor: 'transparent',
      overflow: 'hidden',
      textAlign: 'center',
      width: '100%',
      border: 'none',
      boxShadow: 'none',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
    },
    imageWrapper: {
      position: 'relative',
      width: '100%',
      paddingTop: '100%',
      overflow: 'hidden',
    },
    image: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      transform: 'translate(-50%, -50%)',
      transition: 'transform 0.3s ease',
      display: imageLoaded ? 'block' : 'none', 
    },
    placeholder: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      height: '40px',
      objectFit: 'cover',
      transform: 'translate(-50%, -50%)',
      display: imageLoaded ? 'none' : 'block', 
    },
    logoOverlay: {
      position: 'absolute',
      bottom: '10px',
      right: '10px',
      width: '70px',
      opacity: 0.7,
    },
    body: {
      padding: '20px',
      color: '#10381f',
      fontFamily: 'Corbel, sans-serif',
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    title: {
      fontSize: '1rem',
      fontWeight: '600',
      marginBottom: '5px',
      textTransform: 'uppercase',
      color: '#10381f',
    },
    ref: {
      fontSize: '0.85rem',
      color: '#10381f',
      marginBottom: '15px',
    },
    price: {
      fontSize: '1.25rem',
      fontWeight: '700',
      color: '#10381f',
      marginBottom: '20px',
    },
    buttonWrapper: {
      marginTop: 'auto',
    },
    button: {
      padding: '10px',
      fontSize: '1rem',
      color: '#10381f',
      backgroundColor: 'transparent',
      textTransform: 'uppercase',
      transition: 'all 0.3s ease',
      fontFamily: 'Corbel, sans-serif',
      cursor: 'pointer',
      border: '2px solid #10381f',
    },
    buttonHover: {
      backgroundColor: '#10381f',
      color: '#ffffff',
      transform: 'scale(1.05)',
    },
  };

  return (
    <div className="watch-card-container" style={styles.cardContainer}>
      <div className="card watch-card" style={styles.card}>
        <div className="watch-image-wrapper" style={styles.imageWrapper}>
          <img
            src={placeholderImage}
            alt="placeholder"
            style={styles.placeholder}
          />
          <img
            src={watch.imageUrls[0]}
            loading="lazy"
            className="card-img-top watch-image"
            alt={watch.name}
            style={styles.image}
          />
          <img src={logo} alt="logo" style={styles.logoOverlay} />
        </div>
        <div className="card-body" style={styles.body}>
          <h5 className="card-title" style={styles.title}>
            {watch.brand.toUpperCase()} - {watch.name}
          </h5>
          <p className="card-ref" style={styles.ref}>REF: {watch.refNumber}</p>
          <p className="card-price" style={styles.price}>
            {watch.price ? watch.price.toLocaleString() : t('watch_item.price_on_request')}
          </p>
        </div>
        <div style={styles.buttonWrapper}>
          <Link
            to={`/watch/${watch.id}`}
            className="btn btn-block"
            style={isHovered ? { ...styles.button, ...styles.buttonHover } : styles.button}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {t('watch_item.view_details')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default WatchItem;
