import React from 'react';
import { useTranslation } from 'react-i18next';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import FloatingIcons from './components/FloatingIcons';

const AboutSpacer = () => <div style={{ height: '100px' }}></div>;

const About = () => {
  const { t } = useTranslation(); 

  return (
    <>
      <Navbar />
      <AboutSpacer />
      <div style={styles.container}>
        <div style={styles.heroSection}>
          <h1 style={styles.heroHeading}>{t('about.heading')}</h1>
          <p style={styles.heroSubtitle}>{t('about.subtitle')}</p>
        </div>
        <div style={styles.content}>
          <section style={styles.overviewSection}>
            <h2 style={styles.sectionHeading}>{t('about.our_story')}</h2>
            <p style={styles.sectionText}>{t('about.our_story_text')}</p>
          </section>
          <section style={styles.missionSection}>
            <h2 style={styles.sectionHeading}>{t('about.mission')}</h2>
            <p style={styles.sectionText}>{t('about.mission_text')}</p>
          </section>
        </div>
      </div>
      <FloatingIcons />
      <Footer />
    </>
  );
};

const styles = {
  container: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '20px',
    textAlign: 'center',
    fontFamily: 'Corbel, sans-serif',
    minHeight: '80vh', 
    display: 'flex',
    flexDirection: 'column',
  },
  heroSection: {
    backgroundColor: '#f5f5f5',
    padding: '50px 20px',
    borderRadius: '8px',
    marginBottom: '40px',
  },
  heroHeading: {
    fontSize: '3rem',
    fontWeight: 'bold',
    color: '#333',
    margin: '0',
  },
  heroSubtitle: {
    fontSize: '1.5rem',
    color: '#666',
  },
  content: {
    flex: '1', 
    padding: '20px',
  },
  overviewSection: {
    marginBottom: '40px',
  },
  missionSection: {
    marginBottom: '40px',
  },
};

export default About;
