import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import WatchController from '../../controller/WatchController';
import WatchItem from './WatchItem';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import FloatingIcons from './components/FloatingIcons';
import clock from '../img/clock.jpg';

const WatchesPage = () => {
  const { t } = useTranslation();
  const [watches, setWatches] = useState([]);
  const [filteredWatches, setFilteredWatches] = useState([]);
  const [brandFilter, setBrandFilter] = useState('');
  const [yearFilter, setYearFilter] = useState('');
  const [stateFilter, setStateFilter] = useState('');
  const [stockFilter, setStockFilter] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  const watchListRef = useRef(null);

  const [brands, setBrands] = useState([]);
  const [years, setYears] = useState([]);
  const [states, setStates] = useState([]);

  useEffect(() => {
    AOS.init({ duration: 1000 }); 
  }, []);

  useEffect(() => {
    const fetchWatches = async () => {
      try {
        const data = await WatchController.fetchWatches();
        setWatches(data);
        setFilteredWatches(data);

        const uniqueBrands = [...new Set(data.map((watch) => watch.brand))];
        const uniqueYears = [...new Set(data.map((watch) => watch.year))];
        const uniqueStates = [...new Set(data.map((watch) => watch.state))];

        setBrands(uniqueBrands);
        setYears(uniqueYears);
        setStates(uniqueStates);
      } catch (error) {
        console.error('Failed to fetch watches:', error);
      }
    };

    fetchWatches();
  }, []);

  useEffect(() => {
    let filtered = watches;

    if (brandFilter) {
      filtered = filtered.filter((watch) =>
        watch.brand.toLowerCase().includes(brandFilter.toLowerCase())
      );
    }
    if (yearFilter) {
      filtered = filtered.filter((watch) => watch.year.toString() === yearFilter);
    }
    if (stateFilter) {
      filtered = filtered.filter((watch) => watch.state.toLowerCase() === stateFilter.toLowerCase());
    }
    if (stockFilter) {
      filtered = filtered.filter((watch) =>
        stockFilter === 'yes' ? watch.inStock : !watch.inStock
      );
    }

    setFilteredWatches(filtered);
  }, [brandFilter, yearFilter, stateFilter, stockFilter, watches]);


  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const scrollToSection = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <Navbar />

      <div style={styles.heroSection}>
        <div style={styles.imageShadowOverlay}></div>
        <h1 style={styles.heroTitle}>{t('filters.luxury_watches')}</h1>
        <p style={styles.heroSubtitle}>{t('filters.we_sell_luxury_watches')}</p>
      </div>

      <div style={styles.pageContainer}>
        <div style={styles.filterSidebar}>
          <h3 style={styles.filterTitle}>{t('filters.filters')}</h3>

          <div style={styles.filterGroup}>
            <label>{t('filters.brand')}</label>
            <select
              value={brandFilter}
              onChange={(e) => setBrandFilter(e.target.value)}
              style={styles.filterInput}
            >
              <option value="">{t('filters.all_brands')}</option>
              {brands.map((brand) => (
                <option key={brand} value={brand}>
                  {brand}
                </option>
              ))}
            </select>
          </div>

          <div style={styles.filterGroup}>
            <label>{t('filters.year')}</label>
            <select
              value={yearFilter}
              onChange={(e) => setYearFilter(e.target.value)}
              style={styles.filterInput}
            >
              <option value="">{t('filters.all_years')}</option>
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>

          <div style={styles.filterGroup}>
            <label>{t('filters.state')}</label>
            <select
              value={stateFilter}
              onChange={(e) => setStateFilter(e.target.value)}
              style={styles.filterInput}
            >
              <option value="">{t('filters.all_states')}</option>
              {states.map((state) => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            </select>
          </div>

          <div style={styles.filterGroup}>
            <label>{t('filters.in_stock')}</label>
            <select
              value={stockFilter}
              onChange={(e) => setStockFilter(e.target.value)}
              style={styles.filterInput}
            >
              <option value="">{t('filters.all')}</option>
              <option value="yes">{t('filters.yes')}</option>
              <option value="no">{t('filters.no')}</option>
            </select>
          </div>

          <button
            style={styles.resetButton}
            onClick={() => {
              setBrandFilter('');
              setYearFilter('');
              setStateFilter('');
              setStockFilter('');
            }}
          >
            {t('filters.reset_filters')}
          </button>
        </div>

        <div ref={watchListRef} style={styles.cardContainer}>
          {filteredWatches.map((watch) => (
            <div key={watch.id} style={styles.cardItem} data-aos="fade-up">
              <WatchItem watch={watch} />
            </div>
          ))}
        </div>
      </div>

      <FloatingIcons />
      {isVisible && (
        <button onClick={scrollToTop} style={styles.backToTopButton}>
          ↑
        </button>
      )}
      <Footer />
    </>
  );
};

// CSS styles
const styles = {
  heroSection: {
    height: '50vh',
    backgroundImage: `url(${clock})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    textAlign: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    zIndex: 0,
  },
  imageShadowOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1,
  },
  heroTitle: {
    fontSize: '4rem',
    fontWeight: 'bold',
    marginBottom: '10px',
    fontFamily: 'Red Rose, sans-serif',
    zIndex: 2,
  },
  heroSubtitle: {
    fontSize: '1.5rem',
    fontFamily: 'Red Rose, sans-serif',
    zIndex: 2,
  },
  pageContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '20px',
    maxWidth: '1400px',
    margin: '0 auto',
    fontFamily: 'Corbel, sans-serif',
  },
  filterSidebar: {
    width: '100%',
    maxWidth: '400px',
    margin: '0 auto',
    padding: '20px',
    borderRadius: '10px',
    marginBottom: '20px',
    textAlign: 'center',
  },
  filterTitle: {
    fontSize: '1.5rem',
    marginBottom: '20px',
  },
  filterGroup: {
    marginBottom: '20px',
  },
  filterInput: {
    width: '100%',
    padding: '10px',
    fontSize: '1rem',
    borderRadius: '5px',
    border: '1px solid #ddd',
  },
  resetButton: {
    padding: '10px',
    width: '100%',
    backgroundColor: '#10381f',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  cardContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gap: '20px',
    flex: 2,
  },
  cardItem: {
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    padding: '20px',
  },
  backToTopButton: {
    position: 'fixed',
    bottom: '20px',
    left: '20px',
    padding: '10px 20px',
    backgroundColor: 'black',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    fontSize: '1rem',
    cursor: 'pointer',
    fontFamily: 'Red Rose, sans-serif',
    transition: 'opacity 0.3s',
    zIndex: 1000,
  },
};

export default WatchesPage;
