import React, { useEffect, useState } from 'react';
import UserController from '../../controller/UserController';

const ManageUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const data = await UserController.fetchAllUsers();
        setUsers(data);
      } catch (error) {
        console.error('Failed to fetch users:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  if (loading) {
    return (
      <div style={styles.loadingContainer}>
        <p style={styles.loadingText}>Loading users...</p>
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Manage Users</h2>
      <div style={styles.grid}>
        {users.map(user => (
          <div key={user.uid} style={styles.card}>
            <h3 style={styles.userName}>{user.name}</h3>
            <p><strong>Email:</strong> {user.email}</p>
            <p><strong>Admin:</strong> {user.isAdmin ? 'Yes' : 'No'}</p>
            <p><strong>Created At:</strong> {new Date(user.createdAt).toLocaleString()}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    background: 'linear-gradient(135deg, #f5f7fa, #c3cfe2)',
    borderRadius: '12px',
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
    maxWidth: '1200px',
    margin: '20px auto',
    fontFamily: '"Poppins", sans-serif',
  },
  title: {
    fontSize: '32px',
    marginBottom: '30px',
    textAlign: 'center',
    fontWeight: '600',
    color: '#333',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
    gap: '20px',
  },
  card: {
    padding: '20px',
    borderRadius: '8px',
    backgroundColor: '#fff',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s',
  },
  userName: {
    fontSize: '20px',
    marginBottom: '10px',
    color: '#007bff',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  loadingText: {
    fontSize: '24px',
    color: '#666',
  },
};

export default ManageUsers;
