import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'; 
import { db } from '../../config/firebaseConfig';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { ref, push } from "firebase/database";
import FloatingIcons from './components/FloatingIcons';

const ContactSpacer = () => <div style={{ height: '100px' }}></div>; 
const SubmitSpacer = () => <div style={{ height: '50px' }}></div>; 

const Contact = () => {
  const { t } = useTranslation(); 

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false); 
  const [errors, setErrors] = useState({}); 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = t('contact.errors.nameRequired');
    if (!formData.email) newErrors.email = t('contact.errors.emailRequired');
    if (!formData.phone) newErrors.phone = t('contact.errors.phoneRequired');
    if (!formData.message) newErrors.message = t('contact.errors.messageRequired');
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setIsSubmitting(true);

    try {
      const contactsRef = ref(db, 'contacts'); 
      await push(contactsRef, formData); 
      
      setSubmitSuccess(true);
      setFormData({ name: '', email: '', phone: '', message: '' });
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Navbar />
      <div style={styles.container}>
        <ContactSpacer />
        <div style={styles.content}>
          <h1 style={styles.heading}>{t('contact.title')}</h1>

          {submitSuccess ? (
            <div style={styles.confirmationMessage}>
              <h3>{t('contact.success.title')}</h3>
              <p>{t('contact.success.message')}</p>
            </div>
          ) : (
            <div style={styles.formAndDetails}>
              <div style={styles.formContainer}>
                <h3 style={styles.subHeading}>{t('contact.formTitle')}</h3>
                <form style={styles.form} onSubmit={handleSubmit}>
                  <input
                    type="text"
                    name="name"
                    placeholder={t('contact.namePlaceholder')}
                    value={formData.name}
                    onChange={handleChange}
                    style={styles.input}
                    required
                  />
                  {errors.name && <p style={styles.errorMessage}>{errors.name}</p>}
                  
                  <input
                    type="email"
                    name="email"
                    placeholder={t('contact.emailPlaceholder')}
                    value={formData.email}
                    onChange={handleChange}
                    style={styles.input}
                    required
                  />
                  {errors.email && <p style={styles.errorMessage}>{errors.email}</p>}
                  
                  <input
                    type="tel"
                    name="phone"
                    placeholder={t('contact.phonePlaceholder')}
                    value={formData.phone}
                    onChange={handleChange}
                    style={styles.input}
                    required
                  />
                  {errors.phone && <p style={styles.errorMessage}>{errors.phone}</p>}
                  
                  <textarea
                    name="message"
                    placeholder={t('contact.messagePlaceholder')}
                    value={formData.message}
                    onChange={handleChange}
                    style={styles.textarea}
                    required
                  ></textarea>
                  {errors.message && <p style={styles.errorMessage}>{errors.message}</p>}

                  <button type="submit" style={styles.button} disabled={isSubmitting}>
                    {isSubmitting ? t('contact.submitting') : t('contact.submit')}
                  </button>
                </form>
              </div>
              
              <div style={styles.detailsContainer}>
                <SubmitSpacer />
                <h3 style={styles.subHeading}>{t('contact.detailsTitle')}</h3>
                <p style={styles.contactDetail}>zagrebluxurywatches@gmail.com</p>
                <p style={styles.contactDetail}>+385 99 2810 099</p>
                <p style={styles.contactDetail}>
                  <strong>{t('contact.location1Title')}:</strong> Preradovićeva 34
                  10000 Zagreb
                </p>
                <div style={styles.mapContainer}>
                  <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2781.1371594303187!2d15.974471500000002!3d45.8085114!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4765d6fbc1483475%3A0x2c967acc73d43932!2sPreradovi%C4%87eva%20ul.%2034%2C%2010000%2C%20Zagreb%2C%20Croatia!5e0!3m2!1sen!2s!4v1726062593706!5m2!1sen!2s" 
                    style={styles.map} 
                    allowFullScreen 
                    loading="lazy"
                  ></iframe>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <ContactSpacer />
      <FloatingIcons />
      <Footer />
    </>
  );
};

const styles = {
  container: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  content: {
    width: '100%',
    textAlign: 'center',
    marginBottom: '40px',
  },
  heading: {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    marginBottom: '20px',
    color: '#10381f',
    fontFamily: 'Red Rose, sans-serif',
  },
  formAndDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    fontFamily: 'Corbel, sans-serif', 
  },
  formContainer: {
    flex: '1',
    minWidth: '300px',
    marginRight: '20px',
  },
  detailsContainer: {
    flex: '1',
    minWidth: '300px',
    textAlign: 'left',
  },
  subHeading: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginBottom: '15px',
    color: '#10381f',
    fontFamily: 'Red Rose, sans-serif',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    marginBottom: '10px',
    padding: '10px',
    fontSize: '1rem',
    borderRadius: '5px',
    border: '1px solid #ccc',
  },
  textarea: {
    marginBottom: '10px',
    padding: '10px',
    fontSize: '1rem',
    borderRadius: '5px',
    border: '1px solid #ccc',
    minHeight: '100px',
  },
  button: {
    padding: '10px',
    fontSize: '1rem',
    color: '#fff',
    backgroundColor: '#10381f',
    borderRadius: '5px',
    border: 'none',
    cursor: 'pointer',
  },
  contactDetail: {
    marginBottom: '10px',
    fontSize: '1rem',
    color: '#555',
  },
  mapContainer: {
    width: '100%',
    height: '400px',
    marginTop: '40px',
    textAlign: 'center',
  },
  map: {
    width: '100%',
    height: '100%',
    border: 'none',
  },
  confirmationMessage: {
    color: '#28a745',
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },
  errorMessage: {
    color: 'red',
    fontSize: '0.9rem',
    marginBottom: '10px',
  },
};

export default Contact;
