import { ref, query, orderByChild, get } from 'firebase/database';
import { Watch } from '../model/Watch';
import { db } from '../config/firebaseConfig';

class WatchController {
  // Fetch watches ordered by createdAt (newest first)
  static async fetchWatches() {
    try {
      // Fetch watches from Firebase
      const watchesRef = ref(db, 'watches');
      const watchesQuery = query(watchesRef, orderByChild('createdAt'));
      const snapshot = await get(watchesQuery);

      if (snapshot.exists()) {
        const data = snapshot.val();
        const watches = Object.keys(data).map((key) => ({
          ...data[key],
          id: key,
        })).reverse();

        return watches;
      } else {
        console.warn('No watches found');
        return [];
      }
    } catch (error) {
      console.error('Failed to fetch watches:', error.message);
      throw new Error('Failed to fetch watches: ' + error.message);
    }
  }

  // Add a new watch
  static async addWatch(name, brand, refNumber, price, imageFiles, year, state, caseSize, inStock) {
    try {
      const newWatch = await Watch.addWatch(name, brand, refNumber, price, imageFiles, year, state, caseSize, inStock);

      // Fetch updated watches
      const updatedWatches = await this.fetchWatches();

      return newWatch;
    } catch (error) {
      console.error('Failed to add watch:', error.message);
      throw new Error('Failed to add watch: ' + error.message);
    }
  }

  // Remove a watch
  static async removeWatch(watchId, imageUrls) {
    try {
      if (typeof watchId !== 'string') {
        throw new Error('Invalid watchId: must be a string.');
      }
      await Watch.removeWatch(watchId, imageUrls);

      // Fetch updated watches
      const updatedWatches = await this.fetchWatches();

      return;
    } catch (error) {
      console.error('Failed to remove watch:', error.message);
      throw new Error('Failed to remove watch: ' + error.message);
    }
  }

  // Update an existing watch
  static async updateWatch(watchId, name, brand, refNumber, price, newImageFiles, existingImageUrls, year, state, caseSize, inStock) {
    try {
      if (typeof watchId !== 'string') {
        throw new Error('Invalid watchId: must be a string.');
      }
      const updatedWatch = await Watch.updateWatch(watchId, name, brand, refNumber, price, newImageFiles, existingImageUrls, year, state, caseSize, inStock);

      // Fetch updated watches
      const updatedWatches = await this.fetchWatches();

      return updatedWatch;
    } catch (error) {
      console.error('Failed to update watch:', error.message);
      throw new Error('Failed to update watch: ' + error.message);
    }
  }
}

export default WatchController;
