import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';

const FloatingIcons = () => {
  return (
    <div style={styles.floatingIconsContainer}>
      <a
        href="https://wa.me/385992810099" 
        target="_blank"
        rel="noopener noreferrer"
        style={styles.icon}
        className="floating-icon"
      >
        <FontAwesomeIcon icon={faWhatsapp} style={styles.iconImage} />
      </a>
      <a
        href="tel:+385992810099" 
        style={styles.icon}
        className="floating-icon"
      >
        <FontAwesomeIcon icon={faPhoneAlt} style={styles.iconImage} />
      </a>
    </div>
  );
};

const styles = {
  floatingIconsContainer: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    zIndex: 1000,
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    backgroundColor: '#000',
    cursor: 'pointer',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  },
  iconImage: {
    fontSize: '24px',
    color: '#fff',
  },
};

export default FloatingIcons;
