// src/model/User.js

import { db } from '../config/firebaseConfig';
import { ref, set, get, update, remove } from 'firebase/database';

class User {
  constructor(uid, email, name, createdAt, isAdmin) {
    this.uid = uid;
    this.email = email;
    this.name = name;
    this.createdAt = createdAt || new Date().toISOString();
    this.isAdmin = isAdmin || false;
  }

  // Save a new user to the database
  static async createUser(uid, email, name) {
    const user = new User(uid, email, name);
    const userRef = ref(db, 'users/' + uid);
    await set(userRef, user);
    return user;
  }

  // Get a user by UID
  static async getUserById(uid) {
    const userRef = ref(db, 'users/' + uid);
    const snapshot = await get(userRef);
    if (snapshot.exists()) {
      return snapshot.val();
    } else {
      throw new Error('User not found');
    }
  }

  // Update a user in the database
  static async updateUser(uid, updates) {
    const userRef = ref(db, 'users/' + uid);
    await update(userRef, updates);
  }

  // Delete a user from the database
  static async deleteUser(uid) {
    const userRef = ref(db, 'users/' + uid);
    await remove(userRef);
  }
}

export default User;
