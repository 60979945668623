import React, { useState, useEffect } from 'react';
import WatchController from '../../../controller/WatchController';
import { Button, TextField, Select, MenuItem, InputLabel, FormControl, Typography, Box, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import DeleteIcon from '@mui/icons-material/Delete';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../../../config/firebaseConfig';
import { Radio } from '@mui/material';

const UpdateWatch = ({ onUpdateWatch, onClose, initialData }) => {
  const [name, setName] = useState('');
  const [brand, setBrand] = useState('');
  const [refNumber, setRefNumber] = useState('');
  const [price, setPrice] = useState('');
  const [year, setYear] = useState('');
  const [state, setState] = useState('NEW');
  const [caseSize, setCaseSize] = useState('');
  const [inStock, setInStock] = useState(true);
  const [images, setImages] = useState([]);
  const [mainImage, setMainImage] = useState(null);  
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (initialData) {
      setName(initialData.name || '');
      setBrand(initialData.brand || '');
      setRefNumber(initialData.refNumber || '');
      setPrice(initialData.price || '');
      setYear(initialData.year || '');
      setState(initialData.state || 'NEW');
      setCaseSize(initialData.caseSize || '');
      setInStock(initialData.inStock || true);
      setImages(initialData.imageUrls.map((url) => ({ preview: url, file: null })));
      setMainImage(initialData.imageUrls[0]);
    }
  }, [initialData]);

  const handleUpdateWatch = async (e) => {
    e.preventDefault();
    setError(null);

    if (!name || !brand || !refNumber || !price || !year || !caseSize || images.length === 0) {
      setError('Please fill out all fields and add at least one image.');
      return;
    }

    setLoading(true);
    try {
      const newImageFiles = images.filter((img) => img.file !== null);
      const existingImageUrls = images.filter((img) => img.file === null).map((img) => img.preview);

      // Prepare the updated watch data
      const updatedWatchData = {
        id: initialData.id,
        name,
        brand,
        refNumber,
        price: price,
        year: parseInt(year),
        state,
        caseSize,
        inStock,
        imageUrls: [...existingImageUrls],
      };

      // Upload new images if they exist
      if (newImageFiles.length > 0) {
        const uploadedImages = await Promise.all(
          newImageFiles.map(async (img) => {
            const imageRef = storageRef(storage, `watches/${img.file.name}`);
            await uploadBytes(imageRef, img.file);
            return await getDownloadURL(imageRef);
          })
        );
        updatedWatchData.imageUrls.push(...uploadedImages);
      }

      // Ensure the main image is placed first in the image array
      if (mainImage) {
        updatedWatchData.imageUrls = [mainImage, ...updatedWatchData.imageUrls.filter((url) => url !== mainImage)];
      }

      await WatchController.updateWatch(
        updatedWatchData.id,
        updatedWatchData.name,
        updatedWatchData.brand,
        updatedWatchData.refNumber,
        updatedWatchData.price,
        newImageFiles,
        updatedWatchData.imageUrls,
        updatedWatchData.year,
        updatedWatchData.state,
        updatedWatchData.caseSize,
        updatedWatchData.inStock
      );

      onUpdateWatch(updatedWatchData); 
      onClose();
    } catch (err) {
      setError('Failed to update watch: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  // Handling image uploads
const onDrop = async (acceptedFiles) => {
  setLoading(true); 

  try {
    const uploadedImages = await Promise.all(
      acceptedFiles.map(async (file) => {
        const imageRef = storageRef(storage, `watches/${file.name}`);
        await uploadBytes(imageRef, file);
        const downloadURL = await getDownloadURL(imageRef);
        return downloadURL; // Return the URL from Firebase
      })
    );

    // Add the uploaded image URLs to the existing images
    setImages((prevImages) => [...prevImages, ...uploadedImages.map((url) => ({ preview: url, file: null }))]);
  } catch (error) {
    console.error('Failed to upload images:', error);
  } finally {
    setLoading(false); // Hide loading indicator after upload
  }
};

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: true,
  });

  // Remove an image from the list
  const removeImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  // Set selected image as the main image
  const selectMainImage = (url) => {
    setMainImage(url);
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Edit Watch</DialogTitle>
      <DialogContent dividers>
        {error && (
          <Typography variant="body2" color="error" align="center">
            {error}
          </Typography>
        )}
        <form onSubmit={handleUpdateWatch}>
          <FormControl fullWidth margin="normal">
            <TextField label="Name" value={name} onChange={(e) => setName(e.target.value)} required />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField label="Brand" value={brand} onChange={(e) => setBrand(e.target.value)} required />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField label="Reference Number" value={refNumber} onChange={(e) => setRefNumber(e.target.value)} required />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField label="Price" value={price} onChange={(e) => setPrice(e.target.value)} required />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField label="Year" type="number" value={year} onChange={(e) => setYear(e.target.value)} required />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>State</InputLabel>
            <Select value={state} onChange={(e) => setState(e.target.value)} required>
              <MenuItem value="NEW">New</MenuItem>
              <MenuItem value="USED">Used</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField label="Case Size" value={caseSize} onChange={(e) => setCaseSize(e.target.value)} required />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>In Stock</InputLabel>
            <Select value={inStock} onChange={(e) => setInStock(e.target.value === 'true')} required>
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <Box {...getRootProps()} sx={{ border: '2px dashed #ccc', padding: 2, textAlign: 'center' }}>
              <input {...getInputProps()} />
              <Typography variant="body2">Drag 'n' drop some images here, or click to select files</Typography>
            </Box>
            {images.length > 0 && (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, marginTop: 2 }}>
                {images.map((image, index) => (
                  image.preview ? ( // Ensure only valid image previews are displayed
                    <Box
                      key={index}
                      position="relative"
                      sx={{ width: '120px', height: '120px', cursor: 'pointer' }}
                      onClick={() => selectMainImage(image.preview)}
                    >
                      <img
                        src={image.preview}
                        alt={`preview-${index}`}
                        style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                      />

                      <Radio
                        checked={mainImage === image.preview}
                        onChange={() => selectMainImage(image.preview)}
                        value={image.preview}
                        sx={{ position: 'absolute', top: 5, left: 5, color: '#fff' }}
                      />

                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation(); 
                          removeImage(index);
                        }}
                        sx={{ position: 'absolute', top: 5, right: 5, backgroundColor: 'rgba(0,0,0,0.5)', color: '#fff' }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  ) : null // Skip rendering if preview is invalid
                ))}
              </Box>
            )}

          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleUpdateWatch} color="primary" variant="contained" disabled={loading}>
          {loading ? 'Updating Watch...' : 'Update Watch'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateWatch;
