import User from '../model/User';
import { auth } from '../config/firebaseConfig';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { ref, get } from 'firebase/database';
import { db } from '../config/firebaseConfig';

class UserController {
  // Register a new user
  static async register(email, password, name) {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Create user model and save it to the database with name
      const newUser = await User.createUser(user.uid, user.email, name);
      return newUser;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  // Login a user
  static async login(email, password) {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      return userCredential.user;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  // Logout the current user
  static async logout() {
    try {
      await signOut(auth);
    } catch (error) {
      throw new Error(error.message);
    }
  }

  // Get user by UID
  static async getUserById(uid) {
    try {
      const user = await User.getUserById(uid);
      return user;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  // Fetch all users
  static async fetchAllUsers() {
    try {
      const usersRef = ref(db, 'users');
      const snapshot = await get(usersRef);
      if (snapshot.exists()) {
        const data = snapshot.val();
        return Object.keys(data).map(key => data[key]);
      } else {
        return [];
      }
    } catch (error) {
      throw new Error('Failed to fetch users: ' + error.message);
    }
  }

  // Update user data
  static async updateUser(uid, updates) {
    try {
      await User.updateUser(uid, updates);
    } catch (error) {
      throw new Error(error.message);
    }
  }

  // Delete a user
  static async deleteUser(uid) {
    try {
      await User.deleteUser(uid);
    } catch (error) {
      throw new Error(error.message);
    }
  }
}

export default UserController;
