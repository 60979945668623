import React, { useEffect, useState } from 'react';
import { ref, onValue } from 'firebase/database';
import { db } from '../../config/firebaseConfig';

const ManageContacts = () => {
  const [contacts, setContacts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const contactsPerPage = 20;

  useEffect(() => {
    const contactsRef = ref(db, 'contacts');
    onValue(contactsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const contactList = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));
        setContacts(contactList);
      }
    });
  }, []);

  // Calculate the contacts to be shown on the current page
  const indexOfLastContact = currentPage * contactsPerPage;
  const indexOfFirstContact = indexOfLastContact - contactsPerPage;
  const currentContacts = contacts.slice(indexOfFirstContact, indexOfLastContact);

  // Logic for pagination
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(contacts.length / contactsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Adjust columns based on screen size using CSS Grid
  const styles = {
    container: {
      padding: '20px',
      maxWidth: '1200px',
      margin: '0 auto',
    },
    heading: {
      fontSize: '2rem',
      textAlign: 'center',
      marginBottom: '20px',
    },
    cardGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', 
      gap: '20px',
    },
    card: {
      backgroundColor: '#fff',
      padding: '20px',
      borderRadius: '10px',
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    },
    cardHeading: {
      fontSize: '1.2rem',
      marginBottom: '10px',
    },
    cardText: {
      fontSize: '1rem',
      marginBottom: '10px',
      color: '#555',
    },
    pagination: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '20px',
    },
    paginationButton: {
      padding: '10px 20px',
      margin: '0 5px',
      backgroundColor: '#007bff',
      color: '#fff',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      transition: 'background-color 0.3s',
    },
    activeButton: {
      backgroundColor: '#0056b3',
    },
  };

  if (contacts.length === 0) {
    return <p>No contact messages available.</p>;
  }

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>Manage Contacts</h2>
      <div style={styles.cardGrid}>
        {currentContacts.map((contact) => (
          <div key={contact.id} style={styles.card}>
            <h3 style={styles.cardHeading}>{contact.name}</h3>
            <p style={styles.cardText}><strong>Email:</strong> {contact.email}</p>
            <p style={styles.cardText}><strong>Phone:</strong> {contact.phone}</p>
            <p style={styles.cardText}><strong>Message:</strong> {contact.message}</p>
          </div>
        ))}
      </div>

      <div style={styles.pagination}>
        {pageNumbers.map((number) => (
          <button
            key={number}
            onClick={() => handleClick(number)}
            style={
              currentPage === number
                ? { ...styles.paginationButton, ...styles.activeButton }
                : styles.paginationButton
            }
          >
            {number}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ManageContacts;
