import { ref, push, set, remove, update } from 'firebase/database';
import { uploadBytes, getDownloadURL, ref as storageRef, deleteObject } from 'firebase/storage';
import { db, storage } from '../config/firebaseConfig';

export class Watch {
  constructor(id, name, brand, refNumber, price, imageUrls, year, state, caseSize, inStock, createdAt) {
    this.id = id;
    this.name = name;
    this.brand = brand;
    this.refNumber = refNumber;
    this.price = price;
    this.imageUrls = imageUrls;
    this.year = year;
    this.state = state;
    this.caseSize = caseSize;
    this.inStock = inStock;
    this.createdAt = createdAt || new Date().toISOString(); // Set the createdAt timestamp
  }

  // Add a new watch to the database
  static async addWatch(name, brand, refNumber, price, imageFiles, year, state, caseSize, inStock) {
    try {
      if (!Array.isArray(imageFiles) || imageFiles.length === 0) {
        throw new Error('No image files provided');
      }

      // Upload image files to Firebase Storage
      const imageUrls = await Promise.all(
        imageFiles.map(async (file) => {
          const fileName = file.name; 
          const imageRef = storageRef(storage, `watches/${fileName}`);

          await uploadBytes(imageRef, file);
          const downloadURL = await getDownloadURL(imageRef);
          return downloadURL;
        })
      );

      // Create a new watch object and save it to the database
      const watchId = push(ref(db, 'watches')).key;
      const createdAt = new Date().toISOString(); 

      const newWatch = new Watch(watchId, name, brand, refNumber, price, imageUrls, year, state, caseSize, inStock, createdAt);
      await set(ref(db, `watches/${watchId}`), newWatch);

      return newWatch;
    } catch (error) {
      console.error('Failed to add watch:', error);
      throw new Error('Failed to add watch');
    }
  }

  // Remove a watch from the database
  static async removeWatch(watchId, imageUrls) {
    try {
      await Promise.all(
        imageUrls.map(async (url) => {
          const filePath = decodeURIComponent(url.split('/o/')[1].split('?')[0]);
          const imageRef = storageRef(storage, filePath);
          await deleteObject(imageRef); 
        })
      );

      await remove(ref(db, `watches/${watchId}`)); 
      return true;
    } catch (error) {
      console.error('Failed to remove watch:', error);
      throw new Error('Failed to remove watch: ' + error.message);
    }
  }

  // Update an existing watch
  static async updateWatch(watchId, name, brand, refNumber, price, newImageFiles, existingImageUrls, year, state, caseSize, inStock) {
    try {
      let imageUrls = existingImageUrls; 

      if (newImageFiles && newImageFiles.length > 0) {
      
        const uploadedImages = await Promise.all(
          newImageFiles.map(async (file) => {
            const fileName = file.name;
            const imageRef = storageRef(storage, `watches/${fileName}`);

            await uploadBytes(imageRef, file);
            const downloadURL = await getDownloadURL(imageRef);
            return downloadURL;
          })
        );

        imageUrls = [...existingImageUrls, ...uploadedImages]; 
      }

      const updatedWatch = {
        name,
        brand,
        refNumber,
        price,
        imageUrls,
        year,
        state,
        caseSize,
        inStock,
        createdAt: new Date().toISOString(), 
      };

      await update(ref(db, `watches/${watchId}`), updatedWatch);
      return updatedWatch;
    } catch (error) {
      console.error('Failed to update watch:', error);
      throw new Error('Failed to update watch');
    }
  }
}
